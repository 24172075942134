import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/Layout";
import "../scss/products.scss";
import "../scss/recipes.scss";
import Helmet from "react-helmet";
import Card from "../components/Card/BasicCard";
import Pagination from "../components/Pagination/Pagination";
import {recipeCards} from "../components/Card/recipeCards";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';

const Recipes = ({ location }) => {
    const getTypeParam = () => {
        let params = new URLSearchParams(location.search);
        let type = params.get('type');

        if (type && (type === 'snack' || type === 'meal')) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        } else {
            return 'View All';
        }
    }

    let type = getTypeParam();

    const [category, setCategory] = useState(type);

    useEffect(() => {

        changeCategory(type);
    }, [type]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [cards, setCards] = useState(recipeCards);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = cards.slice(indexOfFirstRecord, indexOfLastRecord);

    const searchBox = useRef('');
    const [searchBoxState, setSearchBoxState] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const changeCategory = (type) => {
        if (type === "Meal") {
            setCategory("Meals");
        } else if (type === "Snack") {
            setCategory("Snacks");
        } else {
            setCategory(type);
        }

        filterCards(type);
    };

    const filterCards = (type) => {
        let result = [];

        if (type !== 'View All') {
            result = recipeCards.filter(card => card.typeOfMeal === type);
        } else {
            result = recipeCards;
        }

        searchBox.current.value = '';
        setSearchBoxState(false);

        setCurrentPage(1);
        setCards(result);
    };

    const filterCardsByInput = (event) => {
        const value = event.target.value;

        if (value.length > 0) {
            setSearchBoxState(true);
            if (value.length >= 3) {
                setCategory('View All');
                const inputCards = recipeCards.filter(card => card.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
                setCurrentPage(1);
                setCards(inputCards);
            }
        } else {
            setCurrentPage(1);
            setCards(recipeCards);
            setSearchBoxState(false);
        }
    };

    return (
        <Layout title="Plant-based Recipes" description="FIRST PRIDE plant-based recipes, made 100% from plants. A healthier option that tastes just like meat | FIRST PRIDE SG">
            <Helmet>
                <link rel="alternate" href="https://www.firstpride.sg/products" hreflang="en-sg" />
                <link rel="alternate" href="https://www.firstpridethailand.com/products/" hreflang="en-th" />
            </Helmet>
                <div className="recipes">
                    <div className="recipeBanner">
                        <div className="productsBannerContent" style={{ whiteSpace: "pre-wrap" }}>
                            <h1 id="top">RECIPES</h1>
                            <h5 className="p-regular d-none d-md-block">
                                {"COOKING MADE EASY WITH IDEAS "}
                                <b>{"JAM PACKED"}</b>
                                <br />
                                {"WITH "}
                                <b>{"PLANT-BASED GOODNESS"}</b>
                            </h5>
                            <h5 className="p-regular d-block d-md-none">
                                {"COOKING MADE EASY WITH \n IDEAS "}
                                <b>{"JAM PACKED "}</b>
                                {"WITH \n"}
                                <b>{"PLANT-BASED GOODNESS"}</b>
                            </h5>
                        </div>
                    </div>
                    <div className="productsContentWrapper" style={{ whiteSpace: "pre-wrap" }}>
                        <h2>QUICK BROWSE</h2>
                        <div className="filtersGroup">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="dropdownSelect firstFilter">
                                <DropdownToggle caret>{category}</DropdownToggle>
                                <DropdownMenu>
                                <DropdownItem onClick={() => {changeCategory('View All')}}>View All</DropdownItem>
                                <DropdownItem onClick={() => {changeCategory('Meal')}}>Meals</DropdownItem>
                                <DropdownItem onClick={() => {changeCategory('Snack')}}>Snacks</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <div className="categorySelect">
                                <div className={`category ${category === 'View All' && 'selected'} clickable`} onClick={() => {changeCategory('View All')}}>VIEW ALL</div>
                                <div className="category"></div>
                                <div className={`category ${category.includes('Meal') && 'selected'} clickable`} onClick={() => {changeCategory('Meal')}}>MEALS</div>
                                <div className="category"></div>
                                <div className={`category ${category.includes('Snack') && 'selected'} clickable`} onClick={() => {changeCategory('Snack')}}>SNACKS</div>
                            </div>
                            <div className={`wrapper lastFilter ${searchBoxState ? 'active' : ''}`}>
                                <Icon path={mdiMagnify}
                                    title="User Profile"
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                                    color="grey"
                                    className="icon"/>

                                <input ref={searchBox} placeholder="Search for recipe..." type="text" onChange={filterCardsByInput}/>
                            </div>
                        </div>

                        <div className={`${currentRecords.length === 0 ? 'noCards' : 'cardsGrid'}`}>
                            {currentRecords.length === 0 ?
                                <h1>Sorry, no results found</h1>
                                :
                                currentRecords.map((item, i)=>(
                                    <Card
                                    key={i}
                                    title={item.title}
                                    classNames=""
                                    path={item.path}
                                    image={item.desktopImage}
                                    mobileImage={item.mobileImage}
                                    mealType={item.typeOfMeal}
                                    prepTime={item.preparationTime}
                                    isSimpleCard={false}
                                    />
                                    )
                                )
                            }
                        </div>

                        <Pagination
                            href="#top"
                            selectPage={(page) => setCurrentPage(page)}
                            cards={cards}
                            recordsPerPage={recordsPerPage}
                            currentPage={currentPage} />
                    </div>
                </div>
        </Layout>
    );
};

export default Recipes;